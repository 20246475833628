.filter-container {
	margin-top: 54px;
	padding: 1rem;
	overflow-y: scroll;
	max-height: 85vh;
}

.filter-container .back-link {
	display: none;
}

.filter-container .filter-title {
	border-bottom: 1px solid #dee2e6;
	font-size: 13px;
}

.filter-row {
	display: flex;
	justify-content: center;
	align-items: center;
}

@media (max-width: 576px) {
	.filter-container {
		padding: 2.5rem;
		padding-top: 50px;
		background-color: #f8f9ff;
		border-radius: 0px;
		box-shadow: none;
		margin-top: 0px;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		overflow: auto;
	}

	.filter-container .filter-title {
		border-bottom: 0px;
		color: #5d6aff;
		font-size: 15px;
	}

	.filter-container .back-link {
		display: block;
		cursor: pointer;
		transform: translateX(-20px);
	}
}

/* Lenme Score Modal */
.lenme-score-modal .modal-body {
	padding-right: 5rem;
	padding-left: 5rem;
}

.lenme-score-modal .modal-body .modal-title {
	font-size: 16px;
	font-weight: bold;
}

.lenme-score-modal .modal-body .modal-text {
	font-size: 12px;
}

.lenme-score-modal .modal-body .lenme-score-legend {
	text-align: center;
	margin-top: 1rem;
}
/* Lenme Score Modal */
