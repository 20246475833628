/* Start content */
.bank-transfer-wrapper {
	position: relative;
	overflow: hidden;
	/* height: calc(100vh - 60px); */
}

.bank-transfer-content {
	position: relative;
	padding: 2.5rem 3rem;
	overflow: auto;
}

@media (min-width: 320px) {
	.bank-transfer-content {
		padding-right: 1rem;
		padding-left: 1rem;
		height: calc(100vh - 116px);
	}
}

@media (min-width: 576px) {
	.bank-transfer-content {
		padding-right: 16px;
		padding-left: 16px;
		height: calc(100vh - 60px);
	}
}

@media (min-width: 720px) {
	.bank-transfer-content {
		padding-right: 80px;
		padding-left: 80px;
	}
}

@media (min-width: 768px) {
	.bank-transfer-content {
		padding-right: 16px;
		padding-left: 16px;
	}
}

@media (min-width: 1024px) {
	.bank-transfer-content {
		padding-right: 80px;
		padding-left: 80px;
	}
}

@media (min-width: 1295px) {
	.bank-transfer-content {
		padding-right: 25%;
		padding-left: 80px;
	}
}
/* End content */

.bank-transfer-header {
	font-size: 24px;
	font-weight: 600;
}

.bank-transfer-fastlink-container {
	height: calc(100vh - 60px);
}

@media (min-width: 650px) {
	.modal-dialog.bank-transfer-modal {
		max-width: 575px;
		margin: 1.75rem auto;
	}
}

.bank-transfer-modal .buttons-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 1rem;
}

.bank-transfer-modal .buttons-wrapper > .btn {
	margin-top: 1rem;
}

@media (max-width: 576px) {
	.bank-transfer-modal .buttons-wrapper {
		flex-direction: column-reverse;
	}

	.bank-transfer-modal .buttons-wrapper > .btn {
		width: 100%;
	}
}

.modal-close {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 15px;
	font-weight: bold;
	color: #7652ff;
}

.bank-transfer-link-success-title {
	font-size: 25px;
	font-weight: bold;
}

.bank-link-success-button {
	width: 240px;
}

.bank-transfer-title {
	/* color: #000;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.2px; */

	color: #000;
	font-family: "Poppins";
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.2px;
}

.bank-transfer-subtitle {
	color: #4B4B4B;
	/* text-align: center; */
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.117px;
}

.bank-transfer-balance-wrapper {
	font-weight: bold;
	font-size: 20px;
}

.bank-transfer-balance {
	font-size: 30px;
}

.funding-resource-card {
	height: 200px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #242424;
	box-shadow: 0px 0px 10px rgba(207, 207, 207, 0.0529608);
	border-radius: 5px;
	text-align: center;
}

.funding-resource-card:hover {
	border: 2px solid #040d6f;
}

.funding-resource-card-title {
	font-weight: 600;
}

.funding-resource-card-bank-name {
	font-size: 14px;
}

.funding-resource-card-status {
	color: #131526;
	font-family: Poppins;
}

.primary-bank-text {
	color: #040d6f;
	font-size: 14px;
	font-weight: 600;
}

.bank-transfer-button {
	width: 240px;
	color: black;
}

.transfer-disclaimer {
	font-size: 14px;
	color: #e74646;
}

.bank-transfer-popup {
	box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.0647079), 0px -2px 4px rgba(0, 0, 0, 0.0374491);
	border-radius: 19px;
}

.bank-transfer-popup-input {
	width: 100%;
}

.bank-transfer-popup-input .form-control {
	border: 0;
}

.bank-transfer-popup-input .form-group {
	margin-bottom: 0;
}

.bank-transfer-popup-input .input-group {
	width: 100%;
}

.bank-transfer-popup-seperator {
	margin: 0;
}

.bank-transfer-popup-name {
	padding-left: 0.75rem;
}

.back-button {
	text-decoration-line: underline;
	color: #7652ff;
	font-size: 16px;
	font-weight: 600;
}

.form-header .main-form-label {
	font-size: 24px;
}

.previous-bank-results {
	position: absolute;
	left: 0;
	color: #7652ff;
	font-size: 18px;
}

.next-bank-results {
	position: absolute;
	right: 0;
	color: #7652ff;
	font-size: 18px;
}

.bank-transfer-popup {
	box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.0647079), 0px -2px 4px rgba(0, 0, 0, 0.0374491);
	border-radius: 19px;
}

.bank-transfer-popup-input {
	width: 100%;
}

.bank-transfer-popup-input .form-control {
	border: 0;
}

.bank-transfer-popup-input .form-group {
	margin-bottom: 0;
}

.bank-transfer-popup-input .input-group {
	width: 100%;
}

.bank-transfer-popup-seperator {
	margin: 0;
}

.bank-transfer-popup-name {
	padding-left: 0.75rem;
}

.form-header .main-form-label {
	font-size: 24px;
}

.previous-bank-results {
	position: absolute;
	left: 0;
	color: #7652ff;
	font-size: 18px;
}

.next-bank-results {
	position: absolute;
	right: 0;
	color: #7652ff;
	font-size: 18px;
}

.bank-transfer-cancel-button {
	color: #ed4848;
}

.transactions-history-container {
	/* flex: 1; */
	width: 100%;	
	/* min-width: 31.875rem;  */
	/* max-width: 50.375rem;  */
	max-width: 94vw;
	height: 26.438rem; 
	overflow-x: scroll;
	/* background: blue;  */
	background: none;
	/* margin-top: 5rem; */
	/* overflow-y: scroll; */
	display: flex;
	flex-direction: column;
	border-radius: 10px 10px 0px 0px;
	/* display: grid; */
	/* grid-column: 4; */
	/* grid-template-columns: 4; */
}
@media (min-width: 767px) {
	.transactions-history-container-webkit-scrollbar {
		width: 4px;
	}

	.transactions-history-container-webkit-scrollbar::-webkit-scrollbar-thumb {
		background-color: #e2e2e2;
		border-radius: 20px;
	}
}
.transactions-history-header {
	color: #000;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.2px;
}

.transactions-history-row-header {
	color: #000;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.2px;
}

.transaction-history-row-container {
	/* overflowY: 'scroll', height: '80%', minWidth: '90vw' */
	overflow-y: scroll;
	/* overflow-x: hidden; */
	/* overflow-x: auto; */
	height: 80%;
	/* min-width: 90vw; */
	/* width: 100%; */
	/* max-width: 95vw; */
	width: 50.5rem;
	/* padding-bottom: 18px; */
}

.transactions-history-row {
	color: #000;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: -0.2px;
}

.tab-item {
    border: none;
    outline: none;
    box-shadow: none; 
    background: none;
	color: #7652FF;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.2px;
	margin-right: 32px;
}

@media (max-width: 640px) { 
    /* For mobile phones: */
    .tab-item {
		margin-right: 10px;
	}
}