.info-card {
	position: relative;
	border-left-width: 3px;
	border-radius: 10px;
	/* border-left-style: solid; */
}

.info-card-label {
	font-size: 12px;
	font-weight: 300;
	color: #404040;
}

.info-card-value {
	font-size: 16px;
	font-weight: bold;
	color: #454545;
}

.info-card-info-icon {
	position: absolute;
	top: 10px;
	right: 8px; 
}
.info-card-info-icon-card {
	top: 10px;
	position: relative;
}

.info-card-crypto-label {
	font-size: 13px;
	font-weight: 400;
	/* line-height: 19.5px; */
	color: #000000;
	font-family: Poppins;
}
