
/* Define the styles for the radio button */
.custom-radio {
    display: inline-block;
    position: relative;
    padding-left: 27px; /* Adjust the padding to position the circle properly */
    line-height: 20px; /* Adjust the line height for better alignment */
    cursor: default
}

/* Hide the default radio input */
.custom-radio input {
    display: none;
    cursor: pointer;
}

/* Style the outer circle */
.custom-radio label {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px; /* Size of the outer circle */
    height: 20px; /* Size of the outer circle */
    border: 2px solid #4846FF; /* Border color of the outer circle */
    border-radius: 50%; /* Make it a circle */
}

/* Style the inner circle */
.custom-radio .inner-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px; /* Size of the inner circle */
    height: 10px; /* Size of the inner circle */
    border-radius: 50%; /* Make it a circle */
    background-color: white; /* Background color inside the inner circle */
    cursor: pointer;
}

/* Style the inner circle when the radio button is checked */
.custom-radio input:checked + label .inner-circle {
    background-color: #4846FF; /* Change the background color of the inner circle when checked */
}
.error label {
    border: 2px solid #f10e23; /* Border color of the outer circle */
   }
