/* Bank Login Widget Start */
.bank-login-widget {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #fff;
	z-index: 1000;
	overflow: auto;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 1rem;
}

.bank-login-widget .frame-container {
	display: flex;
	justify-content: center;
}

.bank-login-widget .frame-wrapper {
	width: 100%;
	max-width: 680px;
	min-height: 535px;
	position: relative;
}

.bank-login-widget .frame-wrapper .loading-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.bank-login-widget .frame-wrapper iframe {
	width: 100%;
	height: 100%;
}
/* Bank Login Widget End */
