.automation-rule-card {
    width: 600px;
    height: 117px;
    /* flex-shrink: 0; */
    border-radius: 10px;
    border: 1px solid #E3E3E3;
    background: #FFF;
    /* background: #FFF; */
    padding: 16px;
    position: relative;
    margin-bottom: 16px;
}

@media (min-width: 400px) {
    .sentence {
        display: block;
    }
}

@media (max-width: 640px) { 
    /* For mobile phones: */
    .automation-rule-card {
        width: 90vw;
    }
}

.edit-button-container {
    /* position: 'absolute', top:  '6px', right: 0, bottom: 0 */
    /* position: absolute;
    top: 6px;
    right: 1px; */
    margin-left: 25px;
    
}
@media (max-width: 640px) { 
    /* For mobile phones: */
    .edit-button-container {
        position: absolute;
        top: 6px;
        right: 1px;
        margin-left: 0;
    }
}

.switch-button {
    height: 24px;
}
@media (max-width: 640px) { 
    /* For mobile phones: */
    .switch-button {
        position: absolute;
        bottom: 16px;
        right: 16px;
    }
}
.insufficient-balance-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.insufficient-balance {
    margin-left: 12px;
}
@media (max-width: 428px) { 
    /* For mobile phones: */
    .automation-rule-card {
        height: fit-content;
    }
    .insufficient-balance-container {
        flex-direction: column;
        /* align-items: last baseline; */
        align-items: flex-start;
    }
    .insufficient-balance {
        margin-top: 15px;
        /* margin-top: 16px; */
        margin-left: 0px;
    }
}