:root {
	--graph-height: 80px;

	/* Legend */
	--legend-width: 88px;

	/* Marker */
	--marker-dimensions: 10px;
	--marker-radius: 5px;

	/* Colors */
	--line-primary: #131526;
	--line-light: #f1f1f1;
	--h-line-color: #4b4b4b;
}

.chart-container {
	position: relative;
	width: 100%;
}

.chart-container > div:first-child {
	height: var(--graph-height);
	background-image: url(../../img/assets/graph-bg.svg);
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

.chart-container .marker {
	position: absolute;
	width: var(--marker-dimensions);
	height: var(--marker-dimensions);
	border-radius: var(--marker-radius);
	border-color: var(--line-primary);
	border-width: 2.5px;
	border-style: solid;
	background-color: #ffffff;
}

.chart-container .legend {
	position: absolute;
	top: -20px;
	color: #4b4b4b !important;
	width: var(--legend-width);
	text-align: center;
	font-weight: 600 !important;
	font-size: 14px;
}

.chart-container .bar {
	position: absolute;
	top: 0;
	bottom: 0;
	height: var(--graph-height);
	width: 0.5px;
	background-color: var(--line-primary);
}

.chart-container .bar > div {
	height: 100%;
}

.chart-container .bar > div > span {
	position: absolute;
	left: -3.3px;
	width: 7px;
	height: 0.5px;
	background-color: var(--line-primary);
}

.chart-container .bar > div > span:first-child {
	top: 0;
}

.chart-container .bar > div > span:last-child {
	bottom: 0;
}

.chart-container .time-interval > span {
	color: #4b4b4b;
	font-weight: 400;
	font-size: 12px;
}

.chart-container .time-interval > span.active {
	color: #000000;
	font-weight: 600;
	font-size: 14px;
}
