.container-automation {
    display: flex;
    flex-direction: column;
    /* flex: 1; */
    align-items: center;
    justify-content: center;
    /* text-align: center; */
    /* background-color: red; */
    height:100%;
    overflow: hidden;
}
.container-editing-automation {
    display: flex;
    flex-direction: column;
    padding: 2px;
    /* flex: 1; */
    /* align-items: center; */
    /* justify-content: center; */
    /* text-align: center; */
    /* background-color: red; */
    /* margin-top: 80px; */
    /* margin-left: 80px; */
    /* height:100%; */
    /* overflow: hidden; */
}
.text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */ 
}
#auto-fund-question-text {
    color: #4B4B4B;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    margin-bottom: 16px;
    max-width: 386px;
    /* width: fit-content; */
    overflow-wrap: break-word;
}
#auto-fund-text {
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px; /* 133.333% */
    margin: 0;
    max-width: 392px;
    overflow-wrap: break-word;
}
.button-container {
    width: 281px;
    height: 50px;
    flex-shrink: 0;
    background-color: aqua;
}
button{
    outline:0 none !important;
}
.button {
    display: flex;
    width: 281px;
    height: 50px;
    flex-shrink: 0; 
    border-radius: 5px;
    background: #000;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
    align-items: center;
    justify-content: center;
    /* Text */
    color: #FFF; 
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal; 
}
.button:disabled {
    cursor: not-allowed;
    /* background: gray; */
    border: 1px solid #D4D4D4;
    background: #FFF;
    /* Text */
    color: #4B4B4B;
}
.button-loan-type {
    display: flex;
    width: 145px;
    height: 40px;
    flex-shrink: 0; 
    border-radius: 200px;
    border: 1px solid #D4D4D4;
    background: #FFF; 
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    /* Text */
    color: #4B4B4B;
    /* font-family: Poppins; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}
.button-loan-type2 {
    display: flex;
    width: 80px;
    height: 40px;
    flex-shrink: 0; 
    border-radius: 5px;
    border: 1px solid #000;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12);
    background: #FFF; 
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    /* Text */
    color: #4B4B4B;
    /* font-family: Poppins; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}

.button-loan-type-selected {
    display: flex;
    width: 145px;
    height: 40px;
    flex-shrink: 0; 
    border-radius: 200px;
    background: #000; 
    /* box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12); */
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    /* Text */
    color: #FFF;
    /* font-family: Poppins; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}

.button-loan-type-selected2 {
    display: flex;
    width: 80px;
    height: 40px;
    flex-shrink: 0;  
    border-radius: 5px;
    background: #000; 
    /* box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12); */
    align-items: center;
    justify-content: center;
    margin-right: 24px;
    /* Text */
    color: #FFF;
    /* font-family: Poppins; */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}

.button-submit {
    display: flex;
    flex-direction: row;
    /* flex: 1; */
    width: 118px;
    height: 34px;
    flex-shrink: 0;  
    /* box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.12); */
    align-items: center;
    justify-content: center;
    /* margin-right: 24px; */
    /* Text */
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 

    border-radius: 4px;
    border: 1px solid #131526;
    background: #131526;
}

.input {
    width: 240px;
    height: 32px;
    /* flex-shrink: 0;  */
    border-radius: 4px;
    border: 1px solid #C6C6C6;
    background: #FFF;
    padding-left: 16px;
    font-size: 12px;
    /* text-align: center; */
    /* transition: border-color 0.3s ease; */   
}
.input-error {
    width: 240px;
    height: 32px;
    /* flex-shrink: 0;  */
    border-radius: 4px;
    border: 1px solid #f10e23;
    background: #FFF;
    padding-left: 16px;
    font-size: 12px;
}
.input-condition {
    width: 120px;
    height: 32px;
    /* flex-shrink: 0;  */
    border-radius: 4px;
    border: 1px solid #C6C6C6;
    background: #FFF;
    padding-left: 16px;
    font-size: 12px;
    color: #000;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* text-align: center; */
    /* transition: border-color 0.3s ease; */  
}
.input:focus{
    outline: none;
    border:2px solid rgba(26,188,156,1);
  }
.input::placeholder {
    color: rgba(0, 0, 0, 0.47);
    /* font-family: Poppins; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */ 
}
.input::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.47);
    /* font-family: Poppins; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */ 
}

.star {
    color: #E74646;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
}

p.condition {
    color: #4B4B4B;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.2px;
}

.horizontal-slider {
    width: 319px;
    height: 24px;
    /* flex-shrink: 0; */
    border-radius: 100px;
    /* border: 1px solid #D8D8D8;  */
    background: #FFF;
}

.slider-thumb {
    font-size: 0.9em;
    text-align: center;
    cursor: pointer;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    border-radius: 100px;
    background: #5D6AFF;
}
.example-track {
    position: relative;
    border-radius: 100px;
    background: #BFC6FF;
    width: 143px;
    height: 24px;
    flex-shrink: 0;
}

.tab-item {
    border: none;
    outline: none;
    box-shadow: none; 
    background: none;
}

.white-search-bar-automation {
    width: 240px;
    height: fit-content; 
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #DEDEDE;
    background: #FFF;
    position: relative;
}

.white-search-bar-input {
    width: 100%;
    height: 40px;
    padding: 14px 11px 14px 11px;
    border-radius: 8px;
    border: none;
    outline: none;
    box-shadow: none; 
    color: black;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    text-align: start;
}
.white-search-bar-input::placeholder {
    color: rgba(0, 0, 0, 0.47);
}
.search-icon {
    display: none;
}
@media (max-width: 640px) { 
    /* For mobile phones: */
    .white-search-bar-input {
        padding-left: 32px; /* Space for the icon */
    }
    .search-icon {
        display: block;
        position: absolute;
        left: 8px; /* Adjust as needed */
        top: 50%;
        transform: translateY(-50%);
    }
}

.CustomSlider-track-test {
    /* margin-top: 20px; */
    display: block;
    /* margin-left: 40px; */
    width: 280px;
    height: 24px;
    border-radius: 100px;
    background-color: #FFF;
    border: 1px solid #D8D8D8;
    /* opacity: 0.3; */
    align-items: center;
    position: relative;
    justify-content: center;
    /* margin-left: -6px; */
    /* margin-left: -6px; */
    /* margin-top: -8px; */
    

}
.error-message {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f10e23;
}
.automation-title {
    color: #4B4B4B;
    font-size: 32px;
    font-weight: 600;
}
@media (max-width: 640px) { 
    /* For mobile phones: */
    .automation-title {
        font-size: 20px;
    }
}
.automation-title-button {
    width: 200px;
    height: 42px;
    border: none;
    background: #000;
    color: #FFFFFF;
    border-radius: 200px;
}
.automation-title-button:disabled {
    cursor: not-allowed;
    /* background: gray; */
    border: 1px solid #D4D4D4;
    background: #FFF;
    /* Text */
    color: #4B4B4B;

}
.automation-condition-container {
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    width: 484px;
    margin-top: 32px;
    background: #FFF;
    padding: 32px;
    padding-top: 16px;
}
@media (max-width: 640px) { 
    /* For mobile phones: */
    .automation-condition-container  {
        width: 90vw;
        min-width: min-content;
    }
}
