.upload-box-container {
	position: relative;
	cursor: pointer;
}

.upload-box-container .form-group > div:focus {
	outline-style: none;
}

.upload-box {
	position: relative;
	background-color: white;
	color: #878787;
	border: 2px dashed #46cbac;
	border-radius: 5px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-content: center;
	flex-direction: column;
	text-align: center;
	font-weight: 600;
}

.upload-box-text {
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	padding-right: 4rem;
	padding-left: 3rem;
	display: flex;
}

.upload-box-text span:first-child {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.upload-box-text .percentage-value {
	margin-left: 1rem;
}

.upload-box-progress {
	background-color: #46cbac;
	height: 100%;
}

.cancel-upload-icon {
	position: absolute;
	top: 16px;
	right: 1rem;
	width: 1.3rem;
	height: 1.3rem;
	cursor: pointer;
	z-index: 10;
}

.cancel-upload-icon img {
	width: 100%;
	height: 100%;
}
