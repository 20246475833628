.react-select {
    font-size: 12px;
    font-weight: 400;
    font-family: Poppins;
    width: 160px;
}
@media (max-width: 640px) { 
    /* For mobile phones: */
    .react-select {
        width: 50%;
    }
    .input-condition {
        width: 100%;
    }
}
.input-container {
    position: relative;
    display: inline-block;
}
.input-condition-container {
    position: relative;
}

.input-condition {
    /* padding-right: 20px; */
    /* position: relative; */
}
/* .input-condition::after {
    content: '%';
    position: absolute;
    left:  30px; 
    top:  50%;
    transform: translateY(-50%);
} */

.percent-symbol {
    position: absolute;
    left: 35px;
    top: 50%;
    transform: translateY(-50%);
}