// Color system
$white: #ffffff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000000;

$grays: ();
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
	(
		"100": $gray-100,
		"200": $gray-200,
		"300": $gray-300,
		"400": $gray-400,
		"500": $gray-500,
		"600": $gray-600,
		"700": $gray-700,
		"800": $gray-800,
		"900": $gray-900,
	),
	$grays
);

$blue: #22418b;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #f10e23;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$darkBlue: #12263f;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
	(
		"blue": $blue,
		"indigo": $indigo,
		"purple": $purple,
		"pink": $pink,
		"red": $red,
		"orange": $orange,
		"yellow": $yellow,
		"green": $green,
		"teal": $teal,
		"cyan": $cyan,
		"white": $white,
		"gray": $gray-600,
		"gray-dark": $gray-800,
	),
	$colors
);

$primary: $blue;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-300;
$dark: $gray-800;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
	(
		"primary": $primary,
		"secondary": $secondary,
		"success": $success,
		"info": $info,
		"warning": $warning,
		"danger": $danger,
		"light": $light,
		"dark": $dark,
	),
	$theme-colors
);

// Grid containers
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px,
);

// Grid breakpoints
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 769px,
	lg: 992px,
	xl: 1200px,
);

// Typography
$font-weight-bold: 600;

// Font size base
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;

// Border Radius
$border-radius-sm: 0.22rem;
$border-radius: 0.32rem;
$border-radius-lg: 0.44rem;

// Allows for customizing button + input radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;

$input-border-radius: $border-radius;
$input-border-radius-lg: $border-radius;
$input-border-radius-sm: $border-radius-sm;

// Input font size
$input-btn-font-size: $font-size-base;
$input-btn-font-size-sm: $font-size-sm;
$input-btn-font-size-lg: $font-size-base;

// Button font size
$btn-font-size: $input-btn-font-size;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-font-size-lg: $input-btn-font-size;

// Buttons + Forms Focus Width
$input-btn-focus-width: 0rem;
$input-focus-border-color: #46cbac;

// Button padding
$btn-padding-y-lg: 0.77rem;

// Form group
$form-group-margin-bottom: 0.5rem;

// Input height
$input-height-lg: calc(1.5em + 1rem + 20px);

// Box Shadows
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow: 0 0.75rem 0.75rem rgba($black, 0.09);
$box-shadow-lg: 0 0.5rem 0.9rem rgba($black, 0.12);

// Pagination
$pagination-color: $black;
$pagination-hover-color: $black;

// Dropdown
$dropdown-link-hover-color: $white;
$dropdown-link-hover-bg: $primary;

// Alerts
$alert-padding-y: 0.5rem;
$alert-padding-x: 0.75rem;

// Checkbox
$custom-checkbox-indicator-border-radius: $border-radius-sm;

// Import bootstrap source and its default variables
@import "../../../node_modules/bootstrap/scss/bootstrap";
